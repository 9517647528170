
import Vue from '@/vueTyped';
import { getAuthUrl } from 'isc-shared/auth';
import BaseButton from './BaseButton.vue';

export default Vue.extend({
    components: {
        BaseButton,
    },

    props: {
        redirect: {
            type: String,
            default: null,
        },
    },

    computed: {
        authUrl() {
            let url = new URL(this.$route.fullPath, location.origin);
            if (this.redirect) {
                try {
                    const redirectIncludesOrigin = String(this.redirect).includes('://');
                    const origin = redirectIncludesOrigin ? undefined : location.origin;
                    url = new URL(String(this.redirect), origin);
                } catch (error) {
                    console.log(error);
                }
            }
            return getAuthUrl(url.href).href;
        },
    },
});
